import { useState } from "react";
import { useTranslation } from "react-i18next";

import { HTTPResponseError, UnknownError } from "services/errors";
import { useUser } from "components/helper/userContext";
import { useSession } from "components/helper/sessionContext";

import {
    getCurrentUser,
    requestSmsVerificationCode,
} from "components/helper/callApi";

import LoginPassword from "./LoginPassword";
import SMSVerificationForm from "./common/SMSVerificationForm";
import { LS_AUTH_TOKEN_KEY } from "constants/constants";
import { useNavigate } from "react-router-dom";

type Props = {
    sessionName: string;
    codeOnline: string;
};

const LoginEmailPasswordSMS = ({ sessionName, codeOnline }: Props) => {
    const i18n = useTranslation();
    const userContext = useUser();
    const { session } = useSession();
    const navigate = useNavigate();

    const [template, setTemplate] = useState("default");
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
    const [successParams, setSuccessParams] = useState<{
        email?: string;
        password?: string;
    }>({});

    const requestVerificationCode = async (email: string, password: string) => {
        try {
            const res = await requestSmsVerificationCode({
                email,
                password,
                codeOnline,
            });

            if (res.status !== 200) {
                throw new HTTPResponseError(res);
            }

            return res.json().then((json) => setPhoneNumber(json.phoneNumber));
        } catch (error) {
            if (!(error instanceof HTTPResponseError)) {
                return Promise.reject(i18n.t("errorUnknown"));
            }

            const res = (error as HTTPResponseError).response;

            switch (res.status) {
                case 401:
                    return Promise.reject(i18n.t("errorCredentialsIncorrect"));
                case 409:
                    return res
                        .json()
                        .catch(() => {
                            return Promise.reject(i18n.t("errorUnknown"));
                        })
                        .then((json) => {
                            if (
                                "ONLINE_SCENARIO_NOT_COMPATIBLE" ===
                                json.errorCode
                            ) {
                                return Promise.reject(
                                    i18n.t(
                                        "passwordEmailErrorScenarioIncorrect"
                                    )
                                );
                            }

                            if (
                                "ONLINE_PHONE_NUMBER_INVALID" === json.errorCode
                            ) {
                                return Promise.reject(
                                    i18n.t("errorPhoneNumberInvalid")
                                );
                            }

                            return Promise.reject(i18n.t("errorUnknown"));
                        });
                default:
                    return Promise.reject(i18n.t("errorUnknown"));
            }
        }
    };

    const loginUserOnSession = async (loginResponse: Response) => {
        const response = await loginResponse.json();

        if (response.token) {
            localStorage.setItem(LS_AUTH_TOKEN_KEY, response.token);
        }

        let res;
        try {
            res = await getCurrentUser(
                //get Proxies if signature is required, to display
                session?.isSignatureRequired ? ["proxies", "isSigned"] : []
            );
        } catch (error) {
            throw new UnknownError();
        }

        if (!res.ok) {
            throw new HTTPResponseError(res);
        }

        res.json().then((data) => userContext.setUser(data));
        navigate(`/session/${codeOnline}`, { replace: true });
    };

    if ("default" === template) {
        return (
            <LoginPassword
                handleSubmit={(email: string, password: string) =>
                    requestVerificationCode(email, password).then(() => {
                        setTemplate("verification-code-form");
                        setSuccessParams({ email, password });
                    })
                }
                sessionName={sessionName}
                codeOnline={codeOnline}
            />
        );
    }

    return (
        <SMSVerificationForm
            sessionName={sessionName}
            phoneNumber={phoneNumber as string}
            codeOnline={codeOnline}
            email={successParams.email as string}
            password={successParams.password as string}
            requestVerificationCode={requestVerificationCode}
            onSubmitSuccess={loginUserOnSession}
        />
    );
};

export default LoginEmailPasswordSMS;
