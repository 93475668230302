import styled from "styled-components";
import WifiIcon from "@mui/icons-material/Wifi";

type Props = {
    answer: string;
    hasResponseBeenValidated: boolean;
    indexOfSeatToBeFilled?: number;
};

interface StyledButtonProps {
    fontSize: string;
}

const ScreenRemote = ({
    answer,
    hasResponseBeenValidated,
    indexOfSeatToBeFilled,
}: Props) => {
    return (
        <Wrapper>
            <Screen>
                <HeaderWrapper data-test-id="screen-ok">
                    <WifiIcon style={{ fontSize: "20px" }} />
                    {hasResponseBeenValidated ? <StyledOk>OK</StyledOk> : null}
                </HeaderWrapper>
                <SpanStep data-test-id="index">
                    {indexOfSeatToBeFilled?.toString().padStart(2, "0")}
                </SpanStep>
                <AnswerWrapper
                    fontSize={answer.length >= 8 ? "22px" : "36px"}
                    data-test-id="screen-answer"
                >
                    {answer}
                </AnswerWrapper>
            </Screen>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    padding: 20px 16px 8px;
    & > span {
        color: red;
        padding: 10px;
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 3px 10px 0px 5px;
    height: 10px;
`;

const Screen = styled.div`
    height: ${(props) => props.theme.screen.height};
    padding-left: 4px;
    background: ${(props) => props.theme.screen.backgroundColor};
    text-align: right;
    font-weight: 700;
    margin: 0 0 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    position: relative;
`;

const SpanStep = styled.span`
    position: absolute;
    display: flex;
    top: 1.8rem;
    font-size: 1.5rem;
    padding-left: 5px;
`;

const StyledOk = styled.div`
    font-size: 13px;
    padding-right: 5px;
    letter-spacing: 0.15rem;
`;

const AnswerWrapper = styled.div<StyledButtonProps>`
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #555;
    padding-right: 16px;
    font-size: ${(props) => props.fontSize};
`;

export default ScreenRemote;
