import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import {
    reverseSessionSubdomain,
    getSubdomainFromSession,
    getCustomization,
} from "components/helper/callApi";
import {
    formatCssVariablesFromCustomization,
    getCustomizationLocalStorageWithExpiry,
    setCustomizationLocalStorageWithExpiry,
    CustomizationProps,
} from "services/customizationService";
import { getSubdomain } from "services/urlService";
import { ERROR } from "types/snackBar.constant";
import { useSubdomain } from "components/helper/SubdomainContext";

interface Customization {
    subdomain: string;
    customization: CustomizationProps;
}

const SubdomainHandler = ({ children }: any) => {
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useTranslation();
    const navigate = useNavigate();
    const { setSubdomainHandlingCompleted } = useSubdomain();

    const subdomain = getSubdomain();
    const currentPath = window.location.pathname;

    const handleReverseSubdomain = async () => {
        try {
            const res = await reverseSessionSubdomain();
            if (res.ok) {
                const data = await res.json();
                //We don't want to load customization in this case because
                //it will be done through the useEffect bellow afterward that the whole redirection process is completed
                navigate(`/login/${data.codeOnline}`);
                return { shouldLoadCustomization: false };
            } else {
                return { shouldLoadCustomization: true };
            }
        } catch (error) {
            enqueueSnackbar(`${i18n.t("errorUnknown")}`, {
                variant: ERROR,
            });
            return { shouldLoadCustomization: false };
        }
    };

    const retrieveSubdomainToRedirectFromSession = async (
        codeOnline: string
    ) => {
        try {
            const res = await getSubdomainFromSession(codeOnline);
            if (res.ok) {
                const data = await res.json();
                window.location.replace(
                    `https://${data.subdomain}.${process.env.REACT_APP_URL}/login/${data.codeOnline}`
                );
                return { shouldRedirect: true };
            }
            return { shouldRedirect: false };
        } catch (error) {
            enqueueSnackbar(`${i18n.t("errorUnknown")}`, {
                variant: ERROR,
            });
            return { shouldRedirect: false };
        }
    };

    const handleCustomization = async () => {
        const customizationIsPresent = getCustomizationLocalStorageWithExpiry();

        if (!customizationIsPresent) {
            try {
                const res = await getCustomization();

                if (res.ok) {
                    const customization = (await res.json()) as Customization;

                    const cssVariablesCustomization =
                        formatCssVariablesFromCustomization(
                            customization.customization
                        );

                    setCustomizationLocalStorageWithExpiry(
                        cssVariablesCustomization
                    );
                }
            } catch (error) {
                enqueueSnackbar(`${i18n.t("errorUnknown")}`, {
                    variant: ERROR,
                });
            }
        }
    };

    useEffect(() => {
        setSubdomainHandlingCompleted(false);

        if (subdomain) {
            if (currentPath === "/") {
                handleReverseSubdomain().then(({ shouldLoadCustomization }) => {
                    if (shouldLoadCustomization) {
                        handleCustomization().then(() => {
                            setSubdomainHandlingCompleted(true);
                        });
                    }
                });
            } else {
                handleCustomization().then(() => {
                    setSubdomainHandlingCompleted(true);
                });
            }
        } else if (currentPath.includes(`/login/`)) {
            const sessionCode = currentPath.split("/login/")[1];
            retrieveSubdomainToRedirectFromSession(sessionCode).then(
                ({ shouldRedirect }) =>
                    setSubdomainHandlingCompleted(!shouldRedirect)
            );
        } else {
            setSubdomainHandlingCompleted(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subdomain, currentPath]);

    return children;
};

export default SubdomainHandler;
