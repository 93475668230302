import styled from "styled-components";
import { useTranslation } from "react-i18next";
import DoneIcon from "@mui/icons-material/Done";

import { ButtonType } from "types/voteEnum";

import Button from "../Button/Button";
import ValidateButton from "../Button/ValidateButton";
import ResetButton from "../Button/ResetButton";

type Props = {
    displayType: string;
    handleValidate: () => void;
    onReset: () => void;
    onButtonClick: (value: string) => void;
    buttonType: ButtonType;
    remoteMaxNumberButton: number;
};

const KeyboardQuizzbox = ({
    displayType,
    handleValidate,
    onReset,
    onButtonClick,
    buttonType,
    remoteMaxNumberButton,
}: Props) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Row>
                <ValidateButton
                    handleValidate={handleValidate}
                    aria-label={t("remote.validate")}
                    data-test-id="btn-validate"
                >
                    <DoneIcon style={{ fontSize: "32px" }} />
                </ValidateButton>
                <ResetButton
                    onReset={onReset}
                    aria-label={t("remote.reset")}
                    data-test-id="btn-reset"
                />
            </Row>
            <Row>
                <Button
                    value={ButtonType.NUMBER === buttonType ? "1" : "A"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 1}
                />
                <Button
                    value={ButtonType.NUMBER === buttonType ? "2" : "B"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 2}
                />
                <Button
                    value={ButtonType.NUMBER === buttonType ? "3" : "C"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 3}
                />
            </Row>
            <Row>
                <Button
                    value={ButtonType.NUMBER === buttonType ? "4" : "D"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 4}
                />
                <Button
                    value={ButtonType.NUMBER === buttonType ? "5" : "E"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 5}
                />
                <Button
                    value={ButtonType.NUMBER === buttonType ? "6" : "F"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 6}
                />
            </Row>
            <Row>
                <Button
                    value={ButtonType.NUMBER === buttonType ? "7" : "G"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 7}
                />
                <Button
                    value={ButtonType.NUMBER === buttonType ? "8" : "H"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 8}
                />
                <Button
                    value={ButtonType.NUMBER === buttonType ? "9" : "I"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 9}
                />
            </Row>
            <Row>
                <Button
                    value="."
                    onButtonClick={onButtonClick}
                    disabled={displayType !== "numeric"}
                />
                <Button
                    value={ButtonType.NUMBER === buttonType ? "0" : "J"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 10}
                />
                <Button
                    value="-"
                    onButtonClick={onButtonClick}
                    disabled={displayType !== "numeric"}
                />
            </Row>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
`;

const Row = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
`;

export default KeyboardQuizzbox;
