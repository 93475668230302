import { useReducer } from "react";
import { QuestionType } from "types/dataVoteType";
import { VoteStatus } from "types/voteEnum";

type Answer = {
    id: string;
    weight: number;
    disableOtherPropositionsIfSelected: boolean;
};

export type VoteState = {
    voterId: string;
    voteStatus: VoteStatus;
    answers: Array<Answer>;
};

type State = Array<VoteState>;

export type Person = {
    displayName: string;
    numTelecoEncrypted: string;
    weight: number;
};

export type VoteStateAction =
    | { type: "updateWeight"; id: string; weight: number; voterId: string }
    | {
          type: "replaceVoteForEveryParticipant";
          id: string;
          weight: number;
      }
    | { type: "sendAnswersRequest"; voterId: string }
    | { type: "sendAnswersSuccess"; voterId: string }
    | { type: "sendAnswersError"; voterId: string }
    | { type: "resetVote"; voterId: string }
    | { type: "resetEveryOneVotes" }
    | { type: "resetProxyVotes" };

function useVoteState(
    question: QuestionType,
    proxies: Array<Person>,
    user: Person
) {
    function reducer(state: State, action: VoteStateAction): State {
        switch (action.type) {
            case "updateWeight": {
                return [
                    ...state.map((vote) => {
                        if (vote.voterId !== action.voterId) {
                            return vote;
                        }

                        const voter = [user, ...proxies].find(
                            (person) =>
                                person.numTelecoEncrypted === vote.voterId
                        ) as Person;

                        const currentVoteShouldDisabledOtherPropositions =
                            (vote.answers.find(
                                (answer) => action.id === answer.id
                            )?.disableOtherPropositionsIfSelected as boolean) &&
                            /* Useful only in case of a vote with distribution
                               We don't want to disable other propositions if the weight is not the total weight */
                            action.weight === voter.weight;

                        const newAnswers = vote.answers.map((answer) => {
                            if (answer.id === action.id) {
                                return { ...answer, weight: action.weight };
                            }

                            return currentVoteShouldDisabledOtherPropositions
                                ? { ...answer, weight: 0 }
                                : answer;
                        });

                        const distributedWeight = newAnswers.reduce(
                            (previousValue, currentValue) =>
                                previousValue + currentValue.weight,
                            0
                        );

                        const numberOfActiveAnswers = newAnswers.filter(
                            (answer) => answer.weight
                        ).length;

                        const getNewVoteStatus = () => {
                            if (distributedWeight >= voter.weight) {
                                if (
                                    currentVoteShouldDisabledOtherPropositions
                                ) {
                                    return VoteStatus.PendingValidation;
                                }

                                if (
                                    numberOfActiveAnswers <
                                    question.minNumberOfAnswers
                                ) {
                                    return VoteStatus.Incomplete;
                                }
                                return VoteStatus.PendingValidation;
                            } else if (0 === distributedWeight) {
                                return VoteStatus.Idle;
                            } else {
                                return VoteStatus.Incomplete;
                            }
                        };

                        return {
                            ...vote,
                            voteStatus: getNewVoteStatus(),
                            answers: newAnswers,
                        };
                    }),
                ];
            }
            case "replaceVoteForEveryParticipant": {
                return [
                    ...state.map((vote) => {
                        const voter = [user, ...proxies].find(
                            (person) =>
                                person.numTelecoEncrypted === vote.voterId
                        ) as Person;

                        const currentVoteShouldDisabledOtherPropositions =
                            vote.answers.find(
                                (answer) => action.id === answer.id
                            )?.disableOtherPropositionsIfSelected as boolean;
                        const newAnswers = vote.answers.map((answer) => {
                            if (answer.id === action.id) {
                                return {
                                    ...answer,
                                    weight:
                                        // In case of unselect proposal the weight is 0
                                        // In case of select proposal the weight is the total weight of user
                                        action.weight === 0
                                            ? action.weight
                                            : voter.weight,
                                };
                            }
                            return currentVoteShouldDisabledOtherPropositions
                                ? { ...answer, weight: 0 }
                                : answer;
                        });
                        const distributedWeight = newAnswers.reduce(
                            (previousValue, currentValue) =>
                                previousValue + currentValue.weight,
                            0
                        );

                        const numberOfActiveAnswers = newAnswers.filter(
                            (answer) => answer.weight
                        ).length;

                        const getNewVoteStatus = () => {
                            if (distributedWeight >= voter.weight) {
                                if (
                                    currentVoteShouldDisabledOtherPropositions
                                ) {
                                    return VoteStatus.PendingValidation;
                                }

                                if (
                                    numberOfActiveAnswers <
                                    question.minNumberOfAnswers
                                ) {
                                    return VoteStatus.Incomplete;
                                }

                                return VoteStatus.PendingValidation;
                            } else if (0 === distributedWeight) {
                                return VoteStatus.Idle;
                            } else {
                                return VoteStatus.Incomplete;
                            }
                        };

                        return {
                            ...vote,
                            voteStatus: getNewVoteStatus(),
                            answers: newAnswers,
                        };
                    }),
                ];
            }
            case "sendAnswersRequest": {
                return [
                    ...state.map((vote) => {
                        if (vote.voterId !== action.voterId) {
                            return vote;
                        }

                        return {
                            ...vote,
                            voteStatus: VoteStatus.Loading,
                        };
                    }),
                ];
            }
            case "sendAnswersSuccess": {
                return [
                    ...state.map((vote) => {
                        if (vote.voterId !== action.voterId) {
                            return vote;
                        }

                        return {
                            ...vote,
                            voteStatus: VoteStatus.Success,
                        };
                    }),
                ];
            }
            case "sendAnswersError": {
                return [
                    ...state.map((vote) => {
                        if (vote.voterId !== action.voterId) {
                            return vote;
                        }

                        return {
                            ...vote,
                            voteStatus: VoteStatus.Error,
                        };
                    }),
                ];
            }
            case "resetVote": {
                return [
                    ...state.map((vote) => {
                        if (vote.voterId !== action.voterId) {
                            return vote;
                        }

                        return {
                            ...vote,
                            answers: createInitialAnswers(
                                question.propositions
                            ),
                            voteStatus: VoteStatus.Idle,
                        };
                    }),
                ];
            }
            case "resetEveryOneVotes": {
                return [
                    ...state.map((vote) => {
                        const newAnswers = vote.answers.map((answer) => {
                            return { ...answer, weight: 0 };
                        });

                        return {
                            ...vote,
                            voteStatus: VoteStatus.Idle,
                            answers: newAnswers,
                        };
                    }),
                ];
            }
            case "resetProxyVotes": {
                return state.map((vote) => {
                    // Reset the weight proxies answers
                    if (
                        proxies.some(
                            (proxy) => proxy.numTelecoEncrypted === vote.voterId
                        )
                    ) {
                        return {
                            ...vote,
                            answers: vote.answers.map((answer) => ({
                                ...answer,
                                weight: 0,
                            })),
                        };
                    }

                    return vote;
                });
            }
        }
    }

    const initialState: State = [user, ...proxies].map((person) => ({
        voterId: person.numTelecoEncrypted,
        voteStatus: VoteStatus.Idle,
        answers: createInitialAnswers(question.propositions),
    }));

    return useReducer(reducer, initialState);
}

type Proposal = {
    id: string;
    disableOtherPropositionsIfSelected: boolean;
};

function createInitialAnswers(proposals: Array<Proposal>): Array<{
    id: string;
    weight: number;
    disableOtherPropositionsIfSelected: boolean;
}> {
    return proposals.map((proposal) => ({
        id: proposal.id,
        weight: 0,
        disableOtherPropositionsIfSelected:
            proposal.disableOtherPropositionsIfSelected,
    }));
}

export default useVoteState;
